.navigation-bar {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  top: 10;
}

.home, .rules, .about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  position: relative;
  font-size: 20px;
  height: 100px;
  color: #aea1a1;
  transition: background-color ease 0.15s;
}

.active {
  color: #000000;
}

.home::after, .rules::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100px; /* Adjust the width of the vertical line */
  width: 1px;
  background-color: #000; /* Adjust the color of the vertical line */
}

.rules::after, .about::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100px;
  width: 1px;
  background-color: #000;
}

.about::before {
  left: 0;
}

.home::after {
  right: 0;
}

.rules::before {
  left: 0;
}

.rules:after {
  right: 0;
}

.home:hover, .rules:hover, .about:hover {
  cursor: pointer;
  background-color: #adcbd7;
}
