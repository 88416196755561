.multi-player-score {
  display: flex;
  justify-content: center;
}

.multi-player-score-heading, .multi-player-score-value {
  color: #BEBEBE;
  font-size: 40px;

  @media(max-width: 576px) {
    font-size: 20px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 25px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 30px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 35px;
  }

  @media(min-width: 1200px) {
    font-size: 40px;
  }
}

.multi-player-score-value {
  margin-left: 3px;
}

.multi-player-color-tiles-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tile-icon-image {
  width: 40%;
  height: 40%;
  background-image: url("../../../src/assets/icons/tile-icon.svg");
}

.multi-player-color-tiles {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  cursor: pointer;

  @media(max-width: 576px) {
    width: 60px;
    height: 60px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    width: 75px;
    height: 75px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    width: 100px;
    height: 100px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    width: 85px;
    height: 85px;
  }

  @media(min-width: 1200px) {
    width: 100px;
    height: 100px;
  }
}

.multi-player-color-tiles-not-allowed {
  cursor: not-allowed
}

.multi-player-dice-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.multi-player-dice {
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 576px) {
    width: 50px;
    height: 50px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    width: 70px;
    height: 70px;
  }

  
  @media(min-width: 1200px) {
    width: 80px;
    height: 80px;
  }
}

.multi-player-dice-color-holder {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000;

  @media(max-width: 576px) {
    width: 20px;
    height: 20px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    width: 25px;
    height: 25px;
  }

  @media(min-width: 1200px) {
    width: 30px;
    height: 30px;
  }
}

.multi-player-roll-dice-button-wrapper {
  display: flex;
  justify-content: center;
}

.multi-player-roll-dice-button {
  margin-top: 10px;

  @media(max-width: 576px) {
    font-size: 10px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 13px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 16px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 19px;
  }

  @media(min-width: 1200px) {
    font-size: 22px;
  }
}

.timer {
  display: flex;
  justify-content: center;
  font-size: 40px;
  color: #bebebe;

  @media(max-width: 576px) {
    font-size: 20px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 25px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 30px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 35px;
  }

  @media(min-width: 1200px) {
    font-size: 40px;
  }
}

.multi-player-roll-dice-text {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 10px;
  color: #bebebe;

  @media(max-width: 576px) {
    font-size: 14px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 18px;
  }

  @media(min-width: 992px) {
    font-size: 22px;
  }
}

.congrats-text {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 50px;
  font-size: 200px; /* Set the font size as desired */
  text-align: center;
  padding: 20px;
  z-index: 9999; /* Set a high z-index value to ensure it appears on top */

  @media(max-width: 576px) {
    font-size: 50px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 80px;
  }

  @media(min-width: 992px) {
    font-size: 150px;
  }
}

.loser-text {
  color: #8F282A; /* Set the text color as desired */
}

.winner-text {
  color: #228D66; /* Set the text color as desired */
}

.lost-subtitle {
  font-size: 80px;
  color: #361E97;

  @media(max-width: 576px) {
    font-size: 40px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 55px;
  }

  @media(min-width: 992px) {
    font-size: 70px;
  }
}

.dice-rolling {
  animation: changeColor 0.5s infinite;
}

@keyframes changeColor {
  0%, 16.66% {
    background-color: red;
  }
  16.67%, 33.33% {
    background-color: orange;
  }
  33.34%, 50% {
    background-color: yellow;
  }
  50.01%, 66.66% {
    background-color: green;
  }
  66.67%, 83.33% {
    background-color: blue;
  }
  83.34%, 100% {
    background-color: purple;
  }
}

.multi-player-tiles-dice {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}