.rules-page {
  height: 100vh;
  overflow: auto;
  background: linear-gradient(#FFFFFF, #D0DEED, #050D0F);
}

.rules-title {
  font-family: 'Pinyon Script', cursive;
  color: #2e2e2e;
  margin-top: 30px;
  text-align: center;

  @media(max-width: 576px) {
    font-size: 60px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 60px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 60px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 80px;
  }

  @media(min-width: 1200px) {
    font-size: 100px;
  }
}

.rule-book {
  width: 70vw;
  margin-top: 60px;
  padding: 0% 10%;
  color: #000000;
  font-size: 25px;
  margin: auto;

  @media(max-width: 576px) {
    font-size: 20px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 20px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 22px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 24px;
  }
}

.rules-line {
  text-align: justify;

  @media(max-width: 576px) {
   
  }

  @media(min-width: 576px) and (max-width: 768px) {
    
  }

  @media(min-width: 768px) and (max-width: 992px) {
   
  }

  @media(min-width: 992px) and (max-width: 1200px) {

  }

  @media(min-width: 1200px) {

  }
}