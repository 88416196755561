.slider-chat {
  position: fixed;
  z-index: 1;
}

.slider-button {
  position: fixed;
  bottom: 15%;
  width: 60px;
  height: 60px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-count {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.slider-icon {
  width: 40px;
  height: 40px;
}

.slider-content {
  height: 400px;
  width: 300px;
  position: fixed;
  bottom: 15%;
  left: -300px;
  transition: transform 0.3s ease;
  background-color: antiquewhite;
  padding: 5px;
}

.slider-button.open,  .slider-content.open {
  transform: translateX(310px);
}

.chat-box {
  height: 370px;
  overflow-y: auto;
  background-color: antiquewhite;
}

#messageText {
  width: 248px;
}