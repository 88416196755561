@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

.cobras-home {
  height: 100vh;
  overflow: auto;
  background: linear-gradient(#FFFFFF, #D0DEED, #050D0F);
}

.title {
  font-size: 100px;
  font-family: 'Pinyon Script', cursive;
  text-align: center;
  margin-top: 30px;
  color: #000000;

  @media(max-width: 576px) {
    font-size: 60px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 60px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 60px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 80px;
  }

  @media(min-width: 1200px) {
    font-size: 100px;
  }
}

.post-title {
  text-align: center;
  margin-left: 400px;
  color: #000000;
  font-size: 25px;

  @media(max-width: 576px) {
    margin-left: 100px;
    font-size: 15px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    margin-left: 200px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    margin-left: 250px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 20px;
    margin-left: 350px;
  }

  @media(min-width: 1200px) {
    margin-left: 400px;
    font-size: 25px;
  }
}

.game-modes {
  display: flex;
  width: 100vw;
  text-align: center;
  justify-content: center;
  margin-top: 250px;

  @media(max-width: 576px) {
    flex-direction: column;
    margin-top: 100px;
    height: 50vh;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    margin-top: 100px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    padding: 0px 100px;
    margin-top: 200px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    margin-top: 175px;
  }

  @media(min-width: 1200px) {
    margin-top: 200px;
  }
}

.game-mode-image {
  width: 300px;
  height: 225px;
  border-radius: 5px;

  @media(max-width: 576px) {
    width: 250px;
    height: 187.5px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    width: 250px;
    height: 187.5px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    width: 250px;
    height: 187.5px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    width: 250px;
    height: 187.5px;
  }

  @media(min-width: 1200px) {
    width: 300px;
    height: 225px;
  }
}

.game-mode-text {
  font-family: 'Dancing Script', cursive;
  font-weight: bold;
  text-decoration: none;
  font-size: 25px;
  color: #ffffff;
}

.game-mode-links {
  text-decoration: none;
  color: inherit;
}

.game-mode-link-seperator {
  width: 300px;
}

.background-changer {
  position: absolute;
  width: 300px;
  height: 225px;
  transition: background-color ease 0.15s;
}

.background-changer:hover {
  background-color: rgba(171, 171, 171, 0.317);
}

.footer {
  color: white;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 50%;
  margin-top: 70px;
  justify-content: space-between;
  font-size: 1.2em;

  @media(min-width: 768px) and (max-width: 1024px) {
    color: black;
    font-size: 1em;
    width: 70%;
    margin-top: 50px;
  }

  @media(max-width: 576px) {
    color: #ffffff;
    font-size: 0.8em;
    width: 80%;
    margin-top: 70px;
  }

  @media(max-width: 456px) {
    margin-top: 100px;
    text-align: center;
    flex-direction: column;
    font-size: 0.9em;
  }
}