.waiting-screen-container {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#FFFFFF, #D0DEED, #050D0F);
}

.corner {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
}

.corner img {
  width: 200px;
  height: 200px;

  @media(max-width: 576px) {
    width: 75px;
    height: 75px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    width: 125px;
    height: 125px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    width: 150px;
    height: 150px;
  }

  @media(min-width: 1200px) {
    width: 200px;
    height: 200px;
  }
}

.person-icon-holder {
  display: flex;
  align-items: center;
}

.person-icon-holder img {

}

.corner .placeholder-image {
  width: 150px;
  height: 150px;

  @media(max-width: 576px) {
    width: 65px;
    height: 65px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    width: 125px;
    height: 125px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    width: 150px;
    height: 150px;
  }

  @media(min-width: 1200px) {
    width: 200px;
    height: 200px;
  }
}

.corner p {
  color: #DD6565;
  text-align: center;

  @media(max-width: 576px) {
    font-size: 10px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    
  }

  @media(min-width: 768px) and (max-width: 992px) {
    
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    
  }

  @media(min-width: 1200px) {
    
  }
}

p.player-text {
  color: white;
}

.placeholder-collection {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: 576px) {
    width: 50px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    
  }

  @media(min-width: 768px) and (max-width: 992px) {
    
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    
  }

  @media(min-width: 1200px) {
    
  }
}

.player-icon-top-left {
  top: 20px;
  left: 20px;
}

.player-icon-top-right {
  top: 20px;
  right: 20px;
}

.player-icon-bottom-left {
  bottom: 0;
  left: 20px;
}

.player-icon-bottom-right {
  bottom: 20px;
  right: 20px;
}

.game-start-button {
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 10%;
  left: 50%;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  background-color: rgb(var(--pure-material-primary-rgb, 0, 0, 0));
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s; 
  transition: background-color 0.2s ease-out;
}

.game-start-button:hover,
.game-start-button:focus {
    background-color: rgb(134, 134, 134);
    box-shadow: 0 2px 4px -1px rgba(145, 145, 145, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.players-required-warning {
  position: absolute;
  color: red;
  text-align: center;
  margin-top: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 1em;

  @media(max-width: 576px) {
    margin-top: 130px;
    font-size: 0.7em;    
  }
}