.cobras-about {
  height: 100vh;
  background: linear-gradient(#FFFFFF, #D0DEED, #050D0F);
  overflow: auto;
  color: #000000;
}

.cobras-about h1 {
  text-align: center;
  margin-top: 30px;
  font-family: 'Pinyon Script', cursive;

  @media(max-width: 576px) {
    font-size: 60px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 60px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 60px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 80px;
  }

  @media(min-width: 1200px) {
    font-size: 100px;
  }
}

.cobras-about p {
  font-size: 25px;

  @media(max-width: 576px) {
    font-size: 20px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 20px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 22px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 24px;
  }
}

.the-game {
  margin-top: 60px;
}

.scrollable {
  width: 50vw;
  margin: auto;
  text-align: center;
}
