.single-player-score {
  display: flex;
  justify-content: center;
}

.single-player-score-heading, .single-player-score-value {
  margin-top: 60px;
  color: #bebebe;
  font-size: 40px;

  @media(max-width: 576px) {
    font-size: 20px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 25px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 30px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 35px;
  }

  @media(min-width: 1200px) {
    font-size: 40px;
  }
}

.single-player-score-value {
  margin-left: 3px;
}

.single-player-tiles-dice {
  height: 100vh;
  z-index: 1;
  position: relative;
}

.back-image {
  height: 100vh;
  width: 100vw;
  background-image: url("../../../src/assets/game-screen-background.jpg");
  filter: blur(13px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  position: fixed;
}

.back-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 61, 70, 0.32); /* Adjust the opacity here */
}

.single-player-color-tiles-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tile-icon-image {
  width: 40%;
  height: 40%;
  background-image: url("../../../src/assets/icons/tile-icon.svg");
}

.single-player-color-tiles {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;

  @media(max-width: 576px) {
    width: 50px;
    height: 50px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    width: 70px;
    height: 70px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    width: 85px;
    height: 85px;
  }

  @media(min-width: 1200px) {
    width: 100px;
    height: 100px;
  }
}

.single-player-color-tiles-not-allowed {
  cursor: not-allowed
}

.single-player-dice-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.single-player-dice {
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 576px) {
    width: 50px;
    height: 50px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    width: 70px;
    height: 70px;
  }

  
  @media(min-width: 1200px) {
    width: 80px;
    height: 80px;
  }
}

.single-player-dice-color-holder {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000;

  @media(max-width: 576px) {
    width: 20px;
    height: 20px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    width: 25px;
    height: 25px;
  }

  @media(min-width: 1200px) {
    width: 30px;
    height: 30px;
  }
}

.single-player-roll-dice-button-wrapper {
  display: flex;
  justify-content: center;
}

.single-player-roll-dice-button {
  margin-top: 10px;

  @media(max-width: 576px) {
    font-size: 10px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 13px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 16px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 19px;
  }

  @media(min-width: 1200px) {
    font-size: 22px;
  }
}

.timer {
  display: flex;
  justify-content: center;
  font-size: 40px;

  @media(max-width: 576px) {
    font-size: 20px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 25px;
  }

  @media(min-width: 768px) and (max-width: 992px) {
    font-size: 30px;
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    font-size: 35px;
  }

  @media(min-width: 1200px) {
    font-size: 40px;
  }
}

.single-player-roll-dice-text {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 10px;
  color: #bebebe;

  @media(max-width: 576px) {
    font-size: 14px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 18px;
  }

  @media(min-width: 992px) {
    font-size: 22px;
  }
}

.congrats-text {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 50px;
  font-size: 200px; /* Set the font size as desired */
  text-align: center;
  padding: 20px;
  z-index: 9999; /* Set a high z-index value to ensure it appears on top */

  @media(max-width: 576px) {
    font-size: 50px;
  }

  @media(min-width: 576px) and (max-width: 768px) {
    font-size: 80px;
  }

  @media(min-width: 992px) {
    font-size: 150px;
  }
}

.winner-text {
  color: #228D66 /* Set the text color as desired */
}

.class-text {
  color: #707070;
  text-align: center;
}